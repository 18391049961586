import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Section } from '@components/Layout';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { H2, Body1, Eyebrow, Bold } from '@components/Text';
import AppStore from '@components/Images/AppStore';
import GooglePlay from '@components/Images/GooglePlay';
import { parseQueryString } from '@utils/usefulFuncs';
import { SignupRewardDisclaimer } from '@components/Disclaimers/SignupRewardDisclaimer';
import { Image } from '@components/Images';
import SignUpForm from '@components/Form/SignUpForm';
import CheckCircle from '@components/Icons/ui/CheckCircle';
import { useWindowSize } from '@hooks/useWindowSize';
import heroImage from '@public/images/TS_newsFeedPhone.png';

const MobileHeadingWrapper = styled.div`
  min-height: 760px;
  max-width: 680px;
  margin: 0 auto;
  padding: 24px 16px 40px 16px;

  h2 {
    * {
      text-transform: uppercase;
      font-size: 36px;
      line-height: 44px;
    }
  }

  @media (max-width: 550px) {
    h2 {
      * {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  @media (max-width: 625px) {
    .heading-wrapper {
      max-width: 500px;
    }
  }

  @media (max-width: 425px) {
    .heading-wrapper {
      max-width: 360px;

      p {
        line-height: 140%;
      }
    }
  }

  @media (max-width: 360px) {
    h2 {
      font-size: 0.8em;
      line-height: 120%;
    }

    p {
      font-size: 0.8em;
      line-height: 120%;
    }
  }
`;

const HeroImageWrapper = styled.div`
  padding-top: 40px;
  max-width: 540px;
  margin-right: 100px;

  @media (max-width: 1200px) {
    margin-right: 40px;
  }
`;
const MobileHeroImageWrapper = styled.div`
  //padding-top: 40px;
  max-width: 540px;
  margin: 0 auto;

  @media (max-width: 999px) {
    max-width: 500px;
  }
`;

const HeroWrapper = styled.div`
  min-height: 740px;
  padding-bottom: 80px;
  h2 {
    font-size: 36px;
    line-height: 1.2;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeroMobile = ({ windowWidth, showAppStoreLinks }) => {
  return (
    <Section>
      <MobileHeadingWrapper>
        <div className="heading-wrapper">
          <Place zIndex="1" width="100%">
            <h2>
              <Bold>Your one-stop investing resource for premier financial content and&nbsp;trading</Bold>
            </h2>
          </Place>

          <Place padding="8px 0 0 0" width="90%" zIndex="1">
            <Body1>
              Buy and sell stocks and ETFs you believe in and stay sharp with news hyper-tailored to your investments
            </Body1>
          </Place>
        </div>

        <div
          style={{
            position: 'relative',
            maxWidth: '660px',
            margin: '0 auto',
          }}
        >
          <MobileHeroImageWrapper>
            <Image src={heroImage} alt="" useNextImage={true} />
          </MobileHeroImageWrapper>

          <Place position="absolute" bottom="10px" left="0px" width="100%">
            <SignUpForm isEmbedded />
          </Place>
        </div>

        <Place padding="4px 0 0 0">
          <Row hAlign="center">
            <Place padding="1px 12px 0px 0" maxWidth="90%">
              <CheckCircle width={18} />
            </Place>
            <Eyebrow>Up to $1,000 cash to invest when you join</Eyebrow>
          </Row>
          <Row hAlign="center">
            <Place padding="12px 0 0 0" maxWidth="436px">
              <SignupRewardDisclaimer />
            </Place>
          </Row>
        </Place>

        {showAppStoreLinks && (
          <Row hAlign="center" padding="24px 0 0 0">
            <Column>
              <AppStore
                width={
                  windowWidth > 600 ? '250px' : windowWidth < 380 ? (windowWidth < 360 ? '130px' : '150px') : '184px'
                }
                isDarkMode={true}
                isDarkBg={true}
              />
            </Column>
            <Column padding="0 4px"></Column>
            <Column padding="0 0 0 0">
              <GooglePlay
                width={
                  windowWidth > 600 ? '250px' : windowWidth < 380 ? (windowWidth < 360 ? '130px' : '150px') : '184px'
                }
                isDarkMode={true}
                isDarkBg={true}
              />
            </Column>
          </Row>
        )}
      </MobileHeadingWrapper>
    </Section>
  );
};

const HeroFull = () => {
  return (
    <HeroWrapper>
      <Section>
        <Wrapper>
          <Column className="section-container" padding="70px 100px 10px 100px">
            <Place position="relative" zIndex="2" style={{ maxWidth: '460px' }}>
              <H2>
                <Bold>
                  Your&nbsp;one-stop investing&nbsp;resource for&nbsp;premier&nbsp;financial
                  content&nbsp;and&nbsp;trading
                </Bold>
              </H2>
              <Place padding="50px 0">
                <Body1>
                  Buy and sell stocks and ETFs you believe in and stay sharp with news hyper-tailored to your
                  investments
                </Body1>
              </Place>
              <Place padding="0 0 20px 0" style={{ maxWidth: '440px' }}>
                <SignUpForm isEmbedded={true} />
                <Row padding="20px 0 0 0">
                  <Place padding="1px 8px 0px">
                    <CheckCircle width={17} />
                  </Place>
                  <Eyebrow>Up to $1,000 cash to invest when you join</Eyebrow>
                </Row>
                <Row padding="12px 0 0 8px">
                  <SignupRewardDisclaimer />
                </Row>
              </Place>
            </Place>
          </Column>

          <HeroImageWrapper>
            <Image src={heroImage} alt="" useNextImage={true} />
          </HeroImageWrapper>
        </Wrapper>
      </Section>
    </HeroWrapper>
  );
};

const Hero_wl = ({ theme, imageTitle }) => {
  const [showAppStoreLinks, setShowAppStoreLinks] = React.useState(false);

  React.useEffect(() => {
    const query = parseQueryString(window.location.search);
    if (query.appStoreCfg != 2) {
      setShowAppStoreLinks(true);
    }
  }, []);

  const { isMobile } = theme;
  const { width, height } = useWindowSize();

  return (
    <Section>
      {isMobile ? (
        <HeroMobile
          theme={theme}
          windowWidth={width}
          windowHeight={height}
          showAppStoreLinks={showAppStoreLinks}
          imageTitle={imageTitle}
        />
      ) : (
        <HeroFull theme={theme} windowWidth={width} windowHeight={height} imageTitle={imageTitle} />
      )}
    </Section>
  );
};

export default withTheme(Hero_wl);
